<template>
  <PolicyPage title="Attendee Privacy Policy" :data="data" />
</template>

<script>
import data from "../../../public/policies/attendee-privacy-policy";
import PolicyPage from "@/components/policies/PolicyPage";

export default {
  name: "AttendeePrivacyPolicy",
  props: {},
  components: { PolicyPage },
  data: () => ({
    data,
  }),
  computed: {},
  methods: {},
};
</script>
